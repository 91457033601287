import oRequest from '@/libs/request.js';
const URL = {
	getRoleList: 'admin/SysRole/getList', // 获取权限列表
	getMenuList: 'admin/SysMenu/getMenuList', // 获取菜单列表
	cleanCache: 'admin/SysMenu/delMenuCache', // 获取菜单列表
	setMenuRole: 'admin/SysMenu/setMenuRole', // 设置菜单权限
	
	
}
export default {
	setMenuRole(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.setMenuRole, data).then(res => {
				resolve(res);
			})
		})
	},
	cleanCache(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.cleanCache, data).then(res => {
				resolve(res);
			})
		})
	},
	getMenuList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getMenuList, data).then(res => {
				resolve(res);
			})
		})
	},
	getRoleList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getRoleList, data).then(res => {
				resolve(res);
			})
		})
	},

}
