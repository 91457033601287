<template>
	<div>
		<el-card>
			<!--上面的表头-->
			<div style="text-align: center; margin-bottom: 20px;">
				<span>请选择用户组：</span>
				<el-select v-model="nRoleId" placeholder="请选择用户组" style="width: 180px;">
					<el-option :label="item.name" :value="item.id" v-for="(item,key) in arrRoleList" :key="key">
					</el-option>
				</el-select>
				<el-button style="margin-left: 10px;" type="primary" v-on:click="checkRole">确定</el-button>
				<el-button style="margin-left: 10px;" type="primary" v-on:click="delMenuCache">清理菜单缓存</el-button>
			</div>

			<!--表格里的内容-->
			<div>
				<el-table :data="arrMenuList" style="width: 100%;margin-bottom: 20px;" row-key="id" border
					default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column prop="name" label="菜单名称" align="center" width="320"></el-table-column>
					<el-table-column prop="icon" label="图标" align="center" width="120">
						<template slot-scope="scope"><i :class="scope.row.icon"></i></template>
					</el-table-column>
					<el-table-column prop="link" label="链接" align="center"></el-table-column>
					<el-table-column prop="state" label="状态" align="center">
						<template slot-scope="scope">
							<span class="tag"
								:style="{'background-color': scope.row.state == 1 ?'green' : 'red'}"></span>{{scope.row.state == 1 ?'启用' : '停用'}}
						</template>
					</el-table-column>
					<el-table-column prop="date" label="权限" align="center" width="360">
						<template slot-scope="scope">
							<!-- <div style="display:inline-block; margin:0 5px; cursor:pointer">
							<span class="tag" :style="{'background-color': scope.row.select == 1 ?'green' : 'red'}"></span>查询
						</div>
						<div style="display:inline-block; margin:0 5px; cursor:pointer">
							<span class="tag" :style="{'background-color': scope.row.update == 1 ?'green' : 'red'}"></span>修改
						</div>
						<div style="display:inline-block; margin:0 5px; cursor:pointer">
							<span class="tag" :style="{'background-color': scope.row.insert == 1 ?'green' : 'red'}"></span>新增
						</div>
						<div style="display:inline-block; margin:0 5px; cursor:pointer">
							<span class="tag" :style="{'background-color': scope.row.delete == 1 ?'green' : 'red'}"></span>删除
						</div> -->
							<div style="display:inline-block; margin:0 5px; cursor:pointer" @click="setMenuRole(scope.row.id, 'select', scope.row.select == 1 ? 0 :1)">
								<el-tag :type="scope.row.select == 1 ?'success' : 'danger'">查询</el-tag>
							</div>
							<div style="display:inline-block; margin:0 5px; cursor:pointer" @click="setMenuRole(scope.row.id, 'update', scope.row.update == 1 ? 0 :1)">
								<el-tag :type="scope.row.update == 1 ?'success' : 'danger'">修改</el-tag>
							</div>
							<div style="display:inline-block; margin:0 5px; cursor:pointer" @click="setMenuRole(scope.row.id, 'insert', scope.row.insert == 1 ? 0 :1)">
								<el-tag :type="scope.row.insert == 1 ?'success' : 'danger'">新增</el-tag>
							</div>
							<div style="display:inline-block; margin:0 5px; cursor:pointer" @click="setMenuRole(scope.row.id, 'delete', scope.row.delete == 1 ? 0 :1)">
								<el-tag :type="scope.row.delete == 1 ?'success' : 'danger'">删除</el-tag>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="操作" width="320" align="center">
						<template slot-scope="scope">
							<el-button type="text">查看详情</el-button>
							<el-button type="text">用户组权限</el-button>
							<el-button type="text">新增子菜单</el-button>
						</template>

					</el-table-column>
				</el-table>
			</div>
		</el-card>
	</div>
</template>
<script>
	import oIndex from './index.js';
	export default {
		data() {
			return {
				arrRoleList: [], // 权限列表
				nRoleId: '', // 当前选择的权限
				arrMenuList: [], // 菜单信息
			};
		},
		created() {
			this.getRoleList();

		},
		methods: {
			/**
			 * 获取权限列表
			 */
			getRoleList() {
				oIndex.getRoleList().then((res) => {
					this.arrRoleList = res.data
					this.nRoleId = res.data[1].id
					this.getMenuList();
				});

			},
			/**
			 * 获取菜单列表
			 */
			getMenuList() {
				let data = {
					role_id: this.nRoleId,
				}
				oIndex.getMenuList(data).then((res) => {
					this.arrMenuList = res
				});
			},
			/**
			 * 选择一个权限组
			 */
			checkRole() {
				this.getMenuList()
			},
			/**
			 * 清除菜单缓存
			 */
			delMenuCache() {
				oIndex.cleanCache().then((res) => {
					this.getMenuList();
				});
			},
			setMenuRole(menuId, role, value){
				let data = {
					menu_id : menuId,
					role_id : this.nRoleId,
					field:role,
					val:value
					
				}
				oIndex.setMenuRole(data).then((res) => {
					this.$message.success(res)
					this.getMenuList();
				});
				console.log(menuId);
				console.log(role);
				console.log(value);
			}

		},
	};
</script>
<style>
	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
</style>
